import produce from 'immer';
import { GET_ALL_PROMO_CODE_SUCCESS, GET_PROMO_CODE_MEMBER_SUCCESS } from './constants';

const INITIAL_STATE = {
  promosList: [],
  promoCodeMembersList: []
};

const promosReducer = produce((draft, action) => {
  switch (action.type) {
    case GET_ALL_PROMO_CODE_SUCCESS:
      draft.promosList = action.payload;
      break;
    case GET_PROMO_CODE_MEMBER_SUCCESS:
    //   draft.promoCodeMembersList = action.payload.promo.rows;
      draft.promoCodeMembersList = action.payload;
      break;

    default:
  }
}, INITIAL_STATE);

export default promosReducer;
