import produce from 'immer';
import { GET_ALL_BUSINESS_SUCCESS } from './constants';

const INITIAL_STATE = {
    businessList: []
};

const usersReducer = produce((draft, action) => {
    switch (action.type) {
        case GET_ALL_BUSINESS_SUCCESS:
            draft.businessList = action.payload;
            break;

        default:
    }
}, INITIAL_STATE);

export default usersReducer;
