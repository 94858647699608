// third-party
import { FormattedMessage } from 'react-intl';
import PaidIcon from '@mui/icons-material/Paid';
// import LockResetIcon from '@mui/icons-material/LockReset';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
// import SyncLockIcon from '@mui/icons-material/SyncLock';
import SellIcon from '@mui/icons-material/Sell';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

// assets
import {
  IconDashboard,
  IconDeviceAnalytics,
  IconUserCheck,
  IconReceipt2,
  IconClipboardList,
  IconPresentationAnalytics,
  IconCurrencyDollarCanadian
} from '@tabler/icons';

// constant
const icons = {
  IconDashboard,
  IconDeviceAnalytics,
  IconUserCheck,
  IconReceipt2,
  IconClipboardList,
  IconPresentationAnalytics,
  IconCurrencyDollarCanadian,
  PaidIcon,
  DashboardIcon,
  PersonIcon,
  ReceiptLongIcon,
  // SyncLockIcon,
  RotateLeftIcon,
  SellIcon
};

// ==============================|| Dashboard MENU ITEMS ||============================== //

const superAdminMenu = {
  id: 'super_admin',
  // title: <FormattedMessage id="Dashboard" />,
  type: 'group',
  children: [
    {
      id: '',
      title: <FormattedMessage id="Dashboard" />,
      type: 'item',
      url: '/',
      icon: icons.DashboardIcon,
      breadcrumbs: false
    },
    {
      id: 'users',
      title: <FormattedMessage id="User Management" />,
      type: 'item',
      url: '/users',
      icon: icons.PersonIcon,
      breadcrumbs: false
    },

    {
      id: 'transactions',
      title: <FormattedMessage id="Transactions" />,
      type: 'item',
      url: '/transactions',
      icon: icons.ReceiptLongIcon,
      breadcrumbs: false
    },
    {
      // Fee System
      id: 'fee',
      title: <FormattedMessage id="Fee Management" />,
      type: 'item',
      icon: icons.PaidIcon,
      url: '/fee',
      breadcrumbs: false
    },

    {
      id: 'resetpassword',
      title: <FormattedMessage id="Reset Password" />,
      type: 'item',
      icon: icons.RotateLeftIcon,
      url: '/resetpassword',
      breadcrumbs: false
    },
    {
      id: 'promoCode',
      title: <FormattedMessage id="Promo Code" />,
      type: 'item',
      icon: icons.SellIcon,
      url: '/promocode',
      breadcrumbs: false
    },

    // {
    //   // Fee System
    //   id: 'Log Out',
    //   title: <FormattedMessage id="Log Out" />,
    //   type: 'item',
    //   icon: icons.PaidIcon,
    //   url: '/logout',
    //   breadcrumbs: false
    // }
  ]
};

export default superAdminMenu;
