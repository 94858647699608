import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import SuperAdminGuard from './AuthGaurd';
// import ResetPassword from 'views/auth/resetPassword';

const SuperAdminDashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const Users = Loadable(lazy(() => import('views/pages/users')));
const NFTS = Loadable(lazy(() => import('views/pages/marketPlace/nfts')));

const ResetPassword = Loadable(lazy(() => import('views/pages/resetpassword')));
const PromoCode = Loadable(lazy(() => import('views/pages/promoCode')));
const PromoCodeMember = Loadable(lazy(() => import('views/pages/promoCodeMember')));
const AddNFTS = Loadable(lazy(() => import('views/pages/marketPlace/nfts/component/addNft')));
const Brands = Loadable(lazy(() => import('views/pages/marketPlace/brands')));
const Categories = Loadable(lazy(() => import('views/pages/marketPlace/categories')));
const SubCategories = Loadable(lazy(() => import('views/pages/marketPlace/subCategories')));
const BrandManagement = Loadable(lazy(() => import('views/pages/marketPlace/brandManagement')));

const Theater = Loadable(lazy(() => import('views/pages/theater')));
const MintedNfts = Loadable(lazy(() => import('views/pages/mintedNfts/index')));
const SaledNfts = Loadable(lazy(() => import('views/pages/soldNfts/index')));
const Events = Loadable(lazy(() => import('views/pages/events')));
const Team = Loadable(lazy(() => import('views/pages/team')));
const Business = Loadable(lazy(() => import('views/pages/business')));
const Individual = Loadable(lazy(() => import('views/pages/individual')));
const FeeSystem = Loadable(lazy(() => import('views/pages/fee')));
const LogOut = Loadable(lazy(() => import('views/pages/logout')));
const OTP = Loadable(lazy(() => import('views/pages/otp')));
// const PRIVACY = Loadable(lazy(() => import('views/pages/privacy')));
// ==============================|| MAIN ROUTING ||============================== //

const ProtectedRoutes = {
  path: '/',
  element: (
    <SuperAdminGuard>
      <MainLayout />
    </SuperAdminGuard>
  ),
  children: [
    {
      path: '/',
      element: <SuperAdminDashboard />
    },
    {
      path: '/users',
      element: <Users />
    },
    {
      path: '/transactions',
      element: <Events />
    },

    {
      path: '/marketPlace/brandManagement',
      element: <BrandManagement />
    },

    {
      path: '/team',
      element: <Team />
    },
    {
      path: '/business',
      element: <Business />
    },
    {
      path: '/individual',
      element: <Individual />
    },
    {
      path: '/fee',
      element: <FeeSystem />
    },
    {
      path: '/resetpassword',
      element: <ResetPassword />
    },
    {
      path: '/promocode',
      element: <PromoCode />
    },
    {
      path: '/promocodemember/:id',
      element: <PromoCodeMember />
    },
    {
      path: '/otp',
      element: <OTP />
    },
    // {
    //   path: '/privacy',
    //   element: <PRIVACY />
    // },
    {
      path: '/logout',
      element: <LogOut />
    }
  ]
};

export default ProtectedRoutes;
