import {
  PROMO_CODE,
  GET_ALL_PROMO_CODE_SUCCESS,
  GET_ALL_PROMO_CODE,
  DELETE_PROMO_CODE,
  GET_PROMO_CODE_MEMBER,
  GET_PROMO_CODE_MEMBER_SUCCESS
} from './constants';

export const promoCode = (data) => {
  return {
    type: PROMO_CODE,
    payload: data
  };
};

export const getAllPromos = (data) => {
  return {
    type: GET_ALL_PROMO_CODE,
    payload: data
  };
};

export const getPromoCodeMembers = (data) => {
  return {
    type: GET_PROMO_CODE_MEMBER,
    payload: data
  };
};

export const getPromoCodeMembersSuccess = (data) => {
  return {
    type: GET_PROMO_CODE_MEMBER_SUCCESS,
    payload: data
  };
};

export const getAllPromosSuccess = (data) => {
  return {
    type: GET_ALL_PROMO_CODE_SUCCESS,
    payload: data
  };
};

export const deletePromoCode = (data) => {
  return {
    type: DELETE_PROMO_CODE,
    payload: data
  };
};
