export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const LOGOUT = 'logout';
export const FORGOT_PASSWORD = 'forgot_password';
export const RESET_PASSWORD = 'reset_password';
export const SET_LOADER = 'set_loader';
export const SET_WALLET_ADDRESS = 'set_wallet_address'
export const OTP = 'otp'
export const OTP_SUCCESS = 'otp_success';

export const RESET_PASSWORD2 = 'reset_password2';