import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const Login = Loadable(lazy(() => import('views/auth/login')));
const ForgetPassword = Loadable(lazy(() => import('views/auth/forgetPassword')));
const ResetPassword = Loadable(lazy(() => import('views/auth/resetPassword')));
const OTP = Loadable(lazy(() => import('views/auth/otp')));
const PRIVACY=Loadable(lazy(() => import('views/pages/privacy')));
// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <Login />
        },
        {
            path: '/forgetpassword',
            element: <ForgetPassword />
        },
        {
            path: '/reset-password-otp',
            element: <ResetPassword />
        },
        {
            path: '/otp',
            element: <OTP />
        } ,
        {
            path: '/privacy',
            element: <PRIVACY />
        },
    ]
};

export default PublicRoutes;
