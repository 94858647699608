import { GET_ALL_BUSINESS, GET_ALL_BUSINESS_SUCCESS, DELETE_USER, ADD_GUEST_USER, ADD_RESTRICTED_USER, CHANGE_USER_STATUS } from './constants';

export const getAllBusiness = (data) => {
    return {
        type: GET_ALL_BUSINESS,
        payload: data
    };
};

export const getAllBusinessSuccess = (data) => {
    return {
        type: GET_ALL_BUSINESS_SUCCESS,
        payload: data
    };
};

export const deleteUser = (data) => {
    return {
        type: DELETE_USER1,
        payload: data
    };
};

export const addGuestUser = (data) => {
    return {
        type: ADD_GUEST_USER,
        payload: data
    };
};

export const addRestrictedUser = (data) => {
    return {
        type: ADD_RESTRICTED_USER,
        payload: data
    };
};

export const changeUserStatus = (data) => {
    return {
        type: CHANGE_USER_STATUS,
        payload: data
    };
};
