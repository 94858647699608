import { all } from 'redux-saga/effects';
import authSaga from '../redux/auth/sagas';
import superAdminDashboardSaga from '../redux/dashboard/sagas';
import usersSaga from '../redux/users/sagas';
import individualUserSaga from '../redux/individual/sagas';
import marketPlaceSaga from '../redux/marketPlace/sagas';
import nftSaga from '../redux/nft/sagas';
import eventsSaga from '../redux/events/sagas';
import mintedNftSaga from '../redux/mintedNft/sagas';
import businessUserSaga from '../redux/business/sagas';
import teamUserSaga from '../redux/team/sagas';
import FeeEnable from '../redux/fee/saga';
import promoCodeSaga from '../redux/promo/sagas';
export default function* rootSaga() {
    yield all([
        authSaga(),
        usersSaga(),
        superAdminDashboardSaga(),
        marketPlaceSaga(),
        nftSaga(),
        eventsSaga(),
        
        mintedNftSaga(),
        individualUserSaga(),
        businessUserSaga(),
        teamUserSaga(),
        FeeEnable(),
        promoCodeSaga()
    ]);
}
