import { combineReducers } from 'redux';
import customizationReducer from './themeReducers/customizationReducer';
import snackbarReducer from './themeReducers/snackbarReducer';
import cartReducer from './themeReducers/cartReducer';
import kanbanReducer from './themeReducers/kanbanReducer';
import AuthReducer from 'redux/auth/reducers';
import superAdminDashboard from 'redux/dashboard/reducers';
import usersReducer from 'redux/users/reducers';
import individualUsersReducer from 'redux/individual/reducers';
import businessUsersReducer from 'redux/business/reducers';
import teamUsersReducer from 'redux/team/reducers';
import marketPlaceReducer from 'redux/marketPlace/reducers';
import nftReducer from 'redux/nft/reducers';
import eventsReducer from '../redux/events/reducers';
import mintedNftReducer from '../redux/mintedNft/reducers';
import FeeReducer from '../redux/fee/reducers';
import promosReducer from '../redux/promo/reducers';
// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    cart: cartReducer,
    kanban: kanbanReducer,

    auth: AuthReducer,
    users: usersReducer,
    individualUsers: individualUsersReducer,
    businesses: businessUsersReducer,
    team: teamUsersReducer,
    superAdminDashboard: superAdminDashboard,
    marketPlace: marketPlaceReducer,
    nft: nftReducer,
    events: eventsReducer,
    mintedNft: mintedNftReducer,
    fee: FeeReducer,
    promo: promosReducer
});

export default rootReducer;
