import axios from 'utils/axios';
import { all, call, fork, put, retry, takeLatest, select } from 'redux-saga/effects';
import { sagaErrorHandler } from 'shared/helperMethods/sagaErrorHandler';
import { makeSelectAuthToken } from '../../shared/helperMethods/Selectors';
import { getAllIndividualsSuccess, getAllIndividuals } from './actions';
import { GET_ALL_INDIVIDUALS, ADD_GUEST_USER, DELETE_USER1, CHANGE_USER_STATUS, ADD_RESTRICTED_USER } from './constants';
import { SetNotification } from 'shared/helperMethods/setNotification';

function* addRestrictedUserRequest({ payload }) {
    try {
        const data = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
        const response = yield axios.put(`admin/users/blockUser/${payload.walletAddress}`, data, {
            headers: {
                Authorization: `Bearer ${yield select(makeSelectAuthToken())}`
            }
        });
        yield put(
            getAllIndividuals({
                search: payload.search,
                page: payload.page,
                limit: payload.limit,
                type: payload.type
            })
        );
        payload.handleClose();
        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield sagaErrorHandler(error.response.data.data);
    }
}

export function* watchAddRestrictedUser() {
    yield takeLatest(ADD_RESTRICTED_USER, addRestrictedUserRequest);
}

function* getAllIndividualsRequest({ payload }) {
    // try {
    //     const axios = require('axios');
    //     let data = '';

    //     let config = {
    //         method: 'get',
    //         maxBodyLength: Infinity,
    //         url: `http://localhost:5000/api/v1/admin/individual/search?username=${payload.search}`,
    //         headers: {
    //             'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTMsImlhdCI6MTY4NTk0OTY1MH0.VIgPG3MjdW4zM_EcGK0Cev1CVxODxAKlW_P-fMYXFRM'
    //         },
    //         data: data
    //     };

    //     const response = yield axios.request(config);
    //     yield put(getAllIndividualsSuccess(response.data.data));
       
    // } catch (error) {
    //     console.log(error, 'error************');
    // }
    try {
        const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
       
        const response = yield axios.get(`admin/individual/search?username=${payload.search}&page=${payload.page}&size=${payload.limit}`,  headers);
        yield put(getAllIndividualsSuccess(response.data.data));
    } catch (error) {
        console.log(error, 'error************');
    }
}

export function* watchGetAllIndividuals() {
    console.log('watchGetAllIndividuals**************');
    yield takeLatest(GET_ALL_INDIVIDUALS, getAllIndividualsRequest);
}

function* addGuestUserRequest({ payload }) {
    let data = {
        walletAddress: payload.walletAddress
    };
    try {
        const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
        const response = yield axios.post(`admin/users/guest`, data, headers);
        yield put(
            getAllIndividuals({
                search: payload.search,
                page: payload.page,
                limit: payload.limit,
                type: payload.type
            })
        );
        payload.handleClose();
        yield SetNotification('success', response.data.message);
    } catch (error) {
        yield sagaErrorHandler(error.response.data.data);
    }
}

export function* watchAddGuestUser() {
    yield takeLatest(ADD_GUEST_USER, addGuestUserRequest);
}

function* deleteUserRequest({ payload }) {
    // try {
    //     const headers = { headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTMsImlhdCI6MTY4NTk2NDE0M30.nT1clizxtBiYhhcuumw5OEqrS32U9mCmBkC09nu7eHk' } };
    //     // const response = yield axios.delete(`/admin/individual/delete/${payload.id}`, headers);
    //     // yield put(
    //     //     getAllIndividuals({
    //     //         search: payload.search,
    //     //         page: payload.page,
    //     //         limit: payload.limit,
    //     //         type: payload.type
    //     //     })
    //     // );
    //     // payload.handleClose();
    //     // yield SetNotification('success', response.data.message);
    // } catch (error) {
    //     yield sagaErrorHandler(error.response.data.data);
    // }
}

export function* watchDeleteUser() {
    yield takeLatest(DELETE_USER1, deleteUserRequest);
}

function* changeStatusRequest({ payload }) {
    // try {
    //     const headers = { headers: { Authorization: `Bearer ${yield select(makeSelectAuthToken())}` } };
    //     const response = yield axios.put(`/admin/users/status/${payload.id}`, {}, headers);
    //     yield put(
    //         getAllIndividuals({
    //             search: payload.search,
    //             page: payload.page,
    //             limit: payload.limit,
    //             type: payload.type
    //         })
    //     );
    //     yield SetNotification('success', response.data.message);
    // } catch (error) {
    //     yield sagaErrorHandler(error.response.data.data);
    // }
}

export function* watchChangeStatus() {
    yield takeLatest(CHANGE_USER_STATUS, changeStatusRequest);
}

export default function* usersSaga() {
    yield all([
        fork(watchGetAllIndividuals),
        fork(watchAddGuestUser),
        fork(watchDeleteUser),
        fork(watchChangeStatus),
        fork(watchAddRestrictedUser)
    ]);
}
